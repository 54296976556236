/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div v-if="pageIsLoaded">
    <!--Teklif Maddeleri Modal -->
    <b-modal
      id="modal-clauses"
      size="xl"

      :title="$t('Teklif Maddeleri')"
      hide-footer
    >
      <b-alert
        variant="primary"
        show
      >
        <div class="alert-body">
          <span>{{ $t('Maddelerin sıralamasını değiştirmek için üzerine tıklayarak sürükleyebilirsiniz.') }}</span>
        </div>
      </b-alert>

      <div class="d-flex align-items-center justify-content-end">

        <b-button
          size="sm"
          variant="outline-danger"
          @click="resetClauses"
        >
          {{ $t('Teklif Maddelerini Standard Maddeler İle Sıfırla') }}
        </b-button>
      </div>
      <hr>
      <draggable
        v-model="proposalData.clauses"
        class="list-group list-group-flush cursor-move"
        tag="ul"
      >
        <transition-group
          type="transition"
          name="flip-list"
        >
          <b-list-group-item
            v-for="(item,index) in proposalData.clauses"
            :key="index"
            tag="li"
            :variant="item.text==''?'danger':''"
            class="d-flex justify-content-between align-items-center"
          >
            <b-form-input
              v-model="item.text"
              :placeholder="$t('Teklif Maddesi Giriniz')"
            />
            <div class="d-flex" />
            <b-button-toolbar>
              <b-button-group>
                <b-button
                  variant="outline-danger"
                  @click="deleteClause(item,index)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-button-group>
            </b-button-toolbar>

          </b-list-group-item>
        </transition-group>
      </draggable>
      <hr>
      <div class="d-flex align-items-center justify-content-end">

        <b-button
          size="sm"
          variant="primary"
          @click="proposalData.clauses.push(JSON.parse(JSON.stringify(blankClauseData)))"
        >
          {{ $t('Yeni Madde Ekle') }}
        </b-button>
      </div>
    </b-modal>
    <!-- Geçici firma oluşturma modal -->
    <b-modal
      id="modal-temporary-customer"
      ref="modal-temporary-customer"
      :title="$t('Geçici Firma Oluştur')"
      hide-footer
      @show="resetTemporaryCustomerData"
      @hidden="resetTemporaryCustomerData"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(saveTemporaryCustomer)"
          @reset.prevent="resetTemporaryCustomerData"
        >

          <validation-provider
            #default="validationContext"
            :name="$t('Geçici Firma Adı')"
            rules="required|min:2"
          >
            <b-form-group
              :label="$t('Geçici Firma Adı')"
              label-for="temporary_customer_name"
            >
              <b-form-input
                id="temporary_customer_name"
                v-model="temporaryCustomerData.name"
                autofocus
                autocomplete="off"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            :name="$t('Yetkili Adı')"
            rules=""
          >
            <b-form-group
              :label="$t('Yetkili Adı')"
              label-for="temporary_customer_manager_name"
            >
              <b-form-input
                id="temporary_customer_manager_name"
                v-model="temporaryCustomerData.manager_name"
                autofocus
                autocomplete="off"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            :name="$t('Yetkili Soyadı')"
            rules=""
          >
            <b-form-group
              :label="$t('Yetkili Soyadı')"
              label-for="temporary_customer_manager_surname"
            >
              <b-form-input
                id="temporary_customer_manager_surname"
                v-model="temporaryCustomerData.manager_surname"
                autofocus
                autocomplete="off"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            :name="$t('Yetkili E-Posta Adresi')"
            rules="email"
          >
            <b-form-group
              :label="$t('Yetkili E-Posta Adresi')"
              label-for="temporary_customer_manager_email"
            >
              <b-form-input
                id="temporary_customer_manager_email"
                v-model="temporaryCustomerData.manager_email"
                autofocus
                autocomplete="off"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Kaydet') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-overlay
      :show="false"
      rounded="sm"
    >
      <b-row>
        <b-col md="9">
          <b-card>
            <b-badge variant="primary">
              Teklif Dili: {{ currentLocale.name }}
            </b-badge>
            <!-- BODY -->
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <!-- Form -->
              <b-form
                class="p-2"
                @submit.prevent="handleSubmit(saveData)"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col md="8">
                    <!-- Firma ad -->
                    <validation-provider
                      #default="validationContext"
                      :name="$t('Firma')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Firma')"
                        label-for="customer_id"
                      >
                        <b-row>
                          <b-col md="8">
                            <vue-autosuggest
                              v-if="!proposalData.customer_is_temporary"
                              :suggestions="customerSuggest.filteredCustomers"
                              :input-props="customerSuggest.inputProps"
                              :state="getValidationState(validationContext)"
                              :value="proposalData.customer_name"
                              @selected="customerSelected"
                              @input="customerOnInputChanged"
                            >
                              <template slot-scope="{suggestion}">
                                <div class="d-flex align-items-center">

                                  <div class="detail ml-50">
                                    <b-card-text class="mb-0">
                                      {{ suggestion.item.name }}
                                    </b-card-text>
                                    <small class="text-muted">
                                      {{ suggestion.item.id }}
                                    </small>
                                  </div>
                                </div>
                              </template>
                            </vue-autosuggest>
                            <b-form-input
                              v-if="proposalData.customer_is_temporary"
                              v-model="proposalData.customer_name"
                              :state="getValidationState(validationContext)"
                              autocomplete="off"
                              readonly
                            />
                          </b-col>
                          <b-col md="4">
                            <b-button
                              v-if="!proposalData.customer_is_temporary"
                              v-b-modal.modal-temporary-customer
                              block
                              variant="outline-primary"
                            >
                              {{ $t('Geçici Firma Oluştur') }}
                            </b-button>
                            <b-button
                              v-if="proposalData.customer_is_temporary"
                              block
                              variant="outline-danger"
                              @click="proposalData.customer_is_temporary=false"
                            >
                              {{ $t('Geçici Firmayı Kaldır') }}
                            </b-button>
                          </b-col></b-row>

                      </b-form-group>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>

                    </validation-provider>
                    <b-form-input
                      v-if="proposalData.customer_is_temporary"
                      :value="proposalData.customer_manager_name+' '+proposalData.customer_manager_surname"

                      autocomplete="off"
                      readonly
                    />
                    <validation-provider
                      v-if="!proposalData.customer_is_temporary"
                      #default="validationContext"
                      :name="$t('Firma Yekilisi')"
                      :rules="!proposalData.customer_is_temporary?'required':''"
                    >
                      <!-- Firma Yetkilisi -->
                      <b-form-group
                        v-if="usersData"
                        :label="$t('Firma Yetkilisi')"
                        label-for="customer_manager_id"
                      >
                        <v-select
                          id="customer_manager_id"
                          v-model="proposalData.customer_manager_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="customerManagerOptions"
                          :state="getValidationState(validationContext)"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="customer_manager_id"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>

                    </validation-provider>
                    <!-- Satış Temsilcisi -->
                    <b-form-group
                      v-if="usersData"
                      :label="$t('Satış Temsilcisi')"
                      label-for="user_id"
                    >
                      <v-select
                        id="user_id"
                        v-model="proposalData.proposal_user"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="usersData"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="user_id"
                      />
                    </b-form-group>
                    <b-form-group>
                      <b-input-group>
                        <b-form-input
                          readonly
                          :value="proposalData.clauses.length+' '+$t('Adet Teklif Maddesi Mevcut')"
                        />
                        <b-input-group-append>
                          <b-button
                            v-b-modal.modal-clauses
                            variant="outline-primary"
                          >
                            {{ $t('Teklif Maddelerini Düzenle') }}
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <!-- Teklif Başlangıç Tarihi -->
                    <b-form-group
                      :label="$t('Teklif Başlangıç Tarihi')"
                      label-for="user_id"
                    >
                      <b-form-datepicker
                        id="example-datepicker"
                        v-model="proposalData.start_date"
                        class="mb-1"
                      />
                    </b-form-group>
                    <validation-provider
                      #default="validationContext"
                      :name="$t('Teklif Geçerlilik Süresi')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Teklif Geçerlilik Süresi')"
                        label-for="validity_days"
                      >
                        <b-row>
                          <b-col md="12">
                            <b-button-group>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-secondary"
                                @click="proposalData.validity_days=1"
                              >
                                {{ $t('1 Gün') }}
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-secondary"
                                @click="proposalData.validity_days=5"
                              >
                                {{ $t('5 Gün') }}
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-secondary"
                                @click="proposalData.validity_days=10"
                              >
                                {{ $t('10 Gün') }}
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-secondary"
                                @click="proposalData.validity_days=15"
                              >
                                {{ $t('15 Gün') }}
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-secondary"
                                @click="proposalData.validity_days=20"
                              >
                                {{ $t('20 Gün') }}
                              </b-button>
                            </b-button-group>
                          </b-col>
                          <b-col md="12">
                            <b-input-group id="validity_days">
                              <b-form-input
                                id="validity_days"
                                v-model="proposalData.validity_days"
                                autofocus
                                autocomplete="off"
                                type="number"
                                :state="getValidationState(validationContext)"
                              />
                              <b-input-group-append is-text>
                                {{ $t('Gün') }}

                              </b-input-group-append>

                            </b-input-group>
                          </b-col>
                        </b-row>

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <validation-provider
                      #default="validationContext"
                      :name="$t('Banka Hesabı')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Banka Hesabı')"
                        label-for="bank_account_id"
                      >

                        <v-select
                          id="bank_account_id"
                          v-model="proposalData.bank_account_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="bankAccountsData"
                          :state="getValidationState(validationContext)"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="bank_account_id"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-card-body class="invoice-padding form-item-section">
                  <div
                    ref="form"
                    class="repeater-form"
                  >
                    <b-row
                      v-for="(item, index) in proposalData.products"

                      :key="index"
                      ref="row"
                      class="pb-2"
                    >

                      <!-- Item Form -->
                      <!-- ? This will be in loop => So consider below markup for single item -->
                      <b-col
                        v-if="!item.is_deleted"
                        cols="12"
                      >

                        <!-- Form Input Fields OR content inside bordered area  -->
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                            <!-- Single Item Form Headers -->
                            <b-col
                              v-if="!item.is_temporary"
                              cols="12"
                              lg="12"
                            >
                              <b-form-group
                                :label="$t('Ürün')"
                                label-for="product"
                              >
                                <vue-autosuggest
                                  :suggestions="productSuggest.filteredProducts"
                                  :input-props="productSuggest.inputProps"
                                  :value="item.product_name"
                                  @selected="productSelected($event,index)"
                                  @input="productOnInputChanged"
                                >
                                  <template slot-scope="{suggestion}">
                                    <div class="d-flex align-items-center">
                                      <b-avatar :src="suggestion.item.image" />
                                      <div class="detail ml-50">
                                        <b-card-text class="mb-0">
                                          {{ suggestion.item.name }}
                                        </b-card-text>
                                        <small class="text-muted">
                                          Stok:{{ suggestion.item.stock }}   Rezerve:{{ suggestion.item.stock_reserved }}
                                        </small>
                                      </div>
                                    </div>
                                  </template>
                                </vue-autosuggest>
                              </b-form-group></b-col>
                            <b-col
                              v-if="item.is_temporary"
                              cols="12"
                              lg="12"
                            >
                              <b-form-group
                                :label="$t('Geçici Ürün Adı')"
                              >
                                <b-form-input
                                  v-model="item.product_name"
                                  placeholder="Geçici Ürün Adı Giriniz"
                                  autofocus
                                  trim
                                />

                              </b-form-group></b-col>
                            <b-col
                              cols="12"
                              lg="4"
                            >
                              <b-form-group
                                :label="$t('Miktar')"
                                label-for="quantity"
                              >
                                <b-input-group id="quantity">
                                  <b-form-input
                                    v-model="item.quantity"
                                    :label="$t('Miktar')"
                                  />
                                  <b-input-group-append>
                                    <b-form-select
                                      v-model="item.unit_id"
                                      :disabled="!item.is_temporary"
                                      :options="unitOptions"
                                    />

                                  </b-input-group-append>
                                </b-input-group>

                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="4"
                            >
                              <b-form-group
                                :label="$t('Vergi')"
                                label-for="tax_id"
                              >
                                <b-form-select
                                  v-model="item.tax_id"
                                  :options="taxOptions"
                                  :disabled="!item.is_temporary"
                                  @change="productTaxTypeChanged(item)"
                                />

                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="4"
                            >   <b-form-group
                              :label="$t('Birim Fiyatı')"
                              label-for="unit_price"
                            >

                              <b-input-group id="unit_price">
                                <cleave
                                  id="unit_price"
                                  v-model="item.unit_price"
                                  size="lg"
                                  class="form-control"
                                  right
                                  :raw="false"
                                  :options="priceInputConfig"
                                />
                                <b-input-group-append>
                                  <b-form-select
                                    v-model="item.currency_id"
                                    :options="currencyOptions"
                                    @change="productCurrencyTypeChanged(item)"
                                  />
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                            </b-col>

                          </b-row>
                          <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="deleteProduct(index)"
                            />

                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="d-flex align-items-center justify-content-end demo-inline-spacing">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="warning"
                      @click="addProduct('temporary')"
                    >
                      {{ $t('Geçici Ürün Ekle') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="primary"
                      @click="addProduct('normal')"
                    >
                      {{ $t('Ürün Ekle') }}
                    </b-button>

                  </div>
                </b-card-body>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    {{ $t('Kaydet') }}
                  </b-button>
                </div>

              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card
            class="card-transaction"
            no-body
          >
            <b-card-header>
              <b-card-title>{{ $t('Döviz Kurları') }}</b-card-title>

            </b-card-header>

            <b-card-body>
              <div
                v-for="(item, index) in currencyOptions.filter(a=>a.is_main==false)"
                :key="index"
                class="transaction-item"
              >
                <b-media no-body>

                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ item.text }}
                    </h6>
                    <small />
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-success"
                >
                  {{ item.currency }}
                </div>
              </div>

            </b-card-body>
          </b-card>
          <b-card
            class="card-transaction"
            no-body
          >
            <b-card-header>
              <b-card-title>{{ $t('Toplam Fiyatlar') }}</b-card-title>

            </b-card-header>

            <b-card-body>
              <div
                v-for="(item, index) in totalPrices"
                :key="index"
                class="transaction-item"
              >
                <b-media no-body>

                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ item.currency_name }}
                    </h6>
                    <small />
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-success"
                >
                  {{ item.price.toFixed(2)+" "+item.currency_name }}
                </div>
              </div>
              <hr>
              <div
                class="transaction-item"
              >
                <b-media no-body>

                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Toplam') }}({{ totalPriceCurrencyConverted.mainCurrency.text }})
                    </h6>

                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder"
                >
                  {{ totalPriceCurrencyConverted.totalPrice.toFixed(2) }} {{ totalPriceCurrencyConverted.mainCurrency.text }}
                </div>
              </div>
              <div
                class="transaction-item"
              >
                <b-media no-body>

                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Kdv') }} ({{ totalPriceCurrencyConverted.mainCurrency.text }})
                    </h6>

                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder"
                >
                  {{ totalPriceCurrencyConverted.totalTax.toFixed(2) }} {{ totalPriceCurrencyConverted.mainCurrency.text }} ({{ totalPriceCurrencyConverted.taxNames }})
                </div>
              </div>
              <div
                class="transaction-item"
              >
                <b-media no-body>

                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Kdv Dahil Toplam') }} ({{ totalPriceCurrencyConverted.mainCurrency.text }})
                    </h6>

                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder"
                >
                  {{ totalPriceCurrencyConverted.totalPriceWithTax.toFixed(2) }} {{ totalPriceCurrencyConverted.mainCurrency.text }}
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row></b-overlay>

  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BModal, BButtonGroup, BListGroupItem, BAlert, BButtonToolbar, VBModal, BBadge, BFormDatepicker, BOverlay, BCardText, BAvatar, BCard, BFormSelect, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import draggable from 'vuedraggable'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import i18n from '@/libs/i18n'
import proposalsStoreModule from '../proposalsStoreModule'

export default {
  components: {
    BRow,
    BAvatar,
    BCard,
    BAlert,
    BModal,
    BBadge,
    BButtonGroup,
    BFormDatepicker,
    BButtonToolbar,
    BCardText,
    BForm,
    BListGroupItem,
    draggable,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BInputGroup,
    VueAutosuggest,
    BInputGroupAppend,
    vSelect,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BOverlay,
    Cleave,
    BMedia,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  data() {
    return {
      required,
      pageReady: false,
      productSuggest: {
        limit: 30,
        filteredProducts: [],
        inputProps: {
          id: 'autosuggest__input',
          class: 'form-control',
          placeholder: "Ürün Adı Giriniz'",

        },

      },
      customerSuggest: {
        limit: 10,
        filteredCustomers: [],
        inputProps: {
          id: 'customer_id',
          class: 'form-control',
          placeholder: 'Firma Adı Giriniz Veya Geçici Firma Ekleyiniz',

        },

      },
    }
  },
  computed: {
    totalPrices() {
      const prices = []
      this.proposalData.products.filter(pd => !pd.is_deleted).forEach(val => {
        let isVal = false
        prices.forEach(pval => {
          if (val.currency_id === pval.currency_id) {
            // eslint-disable-next-line no-param-reassign
            pval.price += (parseFloat(val.unit_price.replace(',', '')) * parseFloat(val.quantity))
            isVal = true
          }
        })
        if (!isVal && val.currency_id !== 0) {
          // eslint-disable-next-line eqeqeq
          const currency = this.currencyOptions.filter(item => item.value === val.currency_id)[0]

          prices.push({ currency_name: currency.text, currency_id: val.currency_id, price: parseFloat(val.unit_price.replace(',', '')) * parseFloat(val.quantity) })
        }
      })

      return prices
    },
    totalPriceCurrencyConverted() {
      const taxNames = []
      let convertedPriceAll = 0
      let taxAll = 0
      let totalPriceWithTaxAll = 0
      const mainCurrency = this.currencyOptions.filter(item => item.is_main === 1)[0]
      this.proposalData.products.filter(pd => !pd.is_deleted).forEach(val => {
        const productCurrency = this.currencyOptions.filter(item => item.value === val.currency_id)[0]
        const totalPrice = val.unit_price.replace(',', '') * val.quantity
        const convertedPrice = (totalPrice * productCurrency.currency) * mainCurrency.currency
        // eslint-disable-next-line no-mixed-operators
        const tax = (convertedPrice * val.tax_rate / 100)
        const totalPriceWithTax = convertedPrice + tax
        convertedPriceAll += convertedPrice
        taxAll += tax
        totalPriceWithTaxAll += totalPriceWithTax
        const filteredTaxName = taxNames.filter(item => item === val.tax_name)
        if (filteredTaxName.length <= 0) {
          taxNames.push(val.tax_name)
        }
      })
      return {
        mainCurrency,
        totalTax: taxAll,
        totalPrice: convertedPriceAll,
        totalPriceWithTax: totalPriceWithTaxAll,
        taxNames: taxNames.join('+'),
      }
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    'proposalData.customer_manager_id': function (newVal, oldVal) {
      this.customerManagerOptions.forEach(val => {
        if (newVal === val.id) {
          this.proposalData.customer_manager_name = val.name
          this.proposalData.customer_manager_surname = val.surname
          this.proposalData.customer_manager_email = val.email
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    'proposalData.customer_is_temporary': function (newVal, oldVal) {
      if (!newVal) {
        this.resetCustomerData()
      }
    },

  },
  mounted() {
    const today = this.$moment()
    this.proposalData.start_date = today.format()
  },
  methods: {
    productTaxTypeChanged(item) {
      this.taxOptions.forEach(val => {
        if (val.value === item.tax_id) {
          // eslint-disable-next-line no-param-reassign
          item.tax_name = val.text
          // eslint-disable-next-line no-param-reassign
          item.tax_rate = val.tax_rate
        }
      })
    },
    productCurrencyTypeChanged(item) {
      this.currencyOptions.forEach(val => {
        if (val.value === item.currency_id) {
          // eslint-disable-next-line no-param-reassign
          item.currency_name = val.text
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    deleteClause(id, index) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Teklif Maddesini Silmek İstediğinizden Emin Misiniz ? '),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.proposalData.clauses.splice(index, 1)
        }
      })
    },
    resetClauses() {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Teklif maddeleri sıfırlandığında aşağıdaki tüm maddeler silinecek ve yerine ayarlarda tanımlanmış olan standard teklif maddeleri getirilecek. '),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Sıfırlama İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.fetchStandardClauses(this.proposalData.lang)
        }
      })
    },
    resetCustomerData() {
      this.proposalData.customer_id = 0
      this.proposalData.customer_name = ''
      this.proposalData.customer_manager_id = 0
      this.proposalData.customer_manager_name = ''
      this.proposalData.customer_manager_surname = ''
      this.proposalData.customer_manager_email = ''
    },
    saveTemporaryCustomer() {
      this.proposalData.customer_is_temporary = true
      this.customerManagerOptions = []
      this.proposalData.customer_id = 0
      this.proposalData.customer_name = this.temporaryCustomerData.name
      this.proposalData.customer_manager_id = 0
      this.proposalData.customer_manager_name = this.temporaryCustomerData.manager_name
      this.proposalData.customer_manager_surname = this.temporaryCustomerData.manager_surname
      this.proposalData.customer_manager_email = this.temporaryCustomerData.manager_email
      this.$refs['modal-temporary-customer'].hide()
    },
    deleteProduct(index) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Ürün Kalemini Silmek İstediğinizden Emin Misiniz ? (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const pdata = this.proposalData.products[index]
          if (pdata.id === 0) {
            this.proposalData.products.splice(index, 1)
          } else {
            this.proposalData.products[index].is_deleted = true
          }
        }
      })
    },
    saveData() {
      let errors = false
      if (this.proposalData.validity_days <= 0) {
        this.$bvToast.toast(this.$t('Teklif geçerlilik süresi sıfırdan büyük olmalıdır'), {
          title: this.$t('Dikkat'),
          variant: 'warning',
          solid: false,
        })
        return
      }
      if (this.proposalData.start_date == null || this.proposalData.start_data === '') {
        this.$bvToast.toast(this.$t('Teklif başlangıç tarihini seçiniz'), {
          title: this.$t('Dikkat'),
          variant: 'warning',
          solid: false,
        })
        return
      }
      if ((this.proposalData.customer_is_temporary !== true && this.proposalData.customer_is_temporary !== 1) && this.proposalData.customer_id === 0) {
        this.$bvToast.toast(this.$t('Teklif için firma yetkilisi seçiniz.'), {
          title: this.$t('Dikkat'),
          variant: 'warning',
          solid: false,
        })
        return
      }
      if (this.proposalData.proposal_user <= 0) {
        this.$bvToast.toast(this.$t('Temsilci Seçiniz. Temsilci alanı boş bırakılamaz'), {
          title: this.$t('Dikkat'),
          variant: 'warning',
          solid: false,
        })
        return
      }
      if (this.proposalData.bank_account_id === 0) {
        this.$bvToast.toast(this.$t('Banka hesabı seçiniz'), {
          title: this.$t('Dikkat'),
          variant: 'warning',
          solid: false,
        })
        return
      }
      if (this.proposalData.customer_is_temporary === false && this.proposalData.customer_id === 0) {
        this.$bvToast.toast(this.$t('Firma seçiniz.'), {
          title: this.$t('Dikkat'),
          variant: 'warning',
          solid: false,
        })
        return
      }

      this.proposalData.products.forEach(val => {
        if (val.is_temporary === 0) {
          if (val.product_id === 0) {
            errors = true
            this.$bvToast.toast(this.$t('Eklediğiniz boş ürün alanları var. Lütfen ürün seçiniz'), {
              title: this.$t('Dikkat'),
              variant: 'warning',
              solid: false,
            })
          }
        } else if (val.product_name === '') {
          errors = true
          this.$bvToast.toast(this.$t('Eklediğiniz bazı geçici ürünlerin ismi boş'), {
            title: this.$t('Dikkat'),
            variant: 'warning',
            solid: false,
          })
        }
        if (val.currency_id <= 0) {
          errors = true
          this.$bvToast.toast(this.$t('Eklediğiniz bazı ürünler için döviz seçimi yapmadınız.'), {
            title: this.$t('Dikkat'),
            variant: 'warning',
            solid: false,
          })
        }
        if (val.unit_id <= 0) {
          errors = true
          this.$bvToast.toast(this.$t('Eklediğiniz bazı ürünler için birim seçimi yapmadınız.'), {
            title: this.$t('Dikkat'),
            variant: 'warning',
            solid: false,
          })
        }
      })

      if (errors) {
        return
      }
      if (this.proposalData.id <= 0) {
        store.dispatch('app-proposal/addProposal', this.proposalData)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.$router.push({ name: 'proposals-list' })
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      } else {
        store.dispatch('app-proposal/updateProposal', this.proposalData)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.$router.push({ name: 'proposals-list' })
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      }
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    addProduct(type = 'normal') {
      const newProductData = JSON.parse(JSON.stringify(this.blankProductData))

      if (type === 'temporary') {
        newProductData.is_temporary = true
      } else {
        newProductData.is_temporary = false
      }
      this.proposalData.products.push(newProductData)
    },
    productSelected(option, index) {
      store.dispatch('app/fetchProduct', { id: option.item.id })
        .then(response => {
          const product = response.data.data
          const productData = this.proposalData.products[index]
          productData.product_id = product.id
          productData.product_name = product.name
          productData.tax_id = product.tax.id//
          productData.tax_name = product.tax.name//
          productData.tax_rate = product.tax.tax_rate//
          productData.currency_id = product.currency_type
          productData.currency_name = product.currency.code
          productData.unit_price = product.price
          productData.unit_name = product.unit.name
          productData.list_price = product.price
          productData.list_price_currency = product.currency.code
          productData.unit_id = product.unit.id
        })
    },

    customerOnInputChanged(text) {
      if (text === '' || text === undefined) {
        return
      }
      store.dispatch('app/suggestCustomers', { name: text, limit: this.customerSuggest.limit, list_for: 'proposal' })
        .then(response => {
          this.customerSuggest.filteredCustomers = [{
            data: response.data.data,
          }]
        })
    },
    productOnInputChanged(text) {
      if (text === '' || text === undefined) {
        return
      }
      store.dispatch('app/suggestProducts', { name: text, limit: this.productSuggest.limit })
        .then(response => {
          this.productSuggest.filteredProducts = [{
            data: response.data.data,
          }]
        })
    },
  },
  setup() {
    const locales = [
      {
        locale: 'tr',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/flags/tr.png'),
        name: 'Türkçe',
      },
      {
        locale: 'en',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },

    ]
    const currentLocale = ref(locales.find(l => l.locale === i18n.locale))
    const pageIsLoaded = ref(false)
    const currencyOptions = ref([])
    const unitOptions = ref([])
    const taxOptions = ref([])
    const customerManagerOptions = ref([])
    const temporaryCustomerData = ref(null)
    const bankAccountsData = ref([])
    const blankClauseData = {
      id: 0,
      text: '',

    }
    const blankTemporaryCompanyData = {
      name: '',
      manager_name: '',
      manager_surname: '',
      manager_email: '',
    }
    const blankProductData = {
      id: 0,
      product_id: 0,
      product_name: '',
      is_temporary: false,
      tax_id: 0,
      tax_name: '',
      currency_name: '',
      tax_rate: 0,
      quantity: 0,
      currency_id: 0,
      unit_price: 0,
      unit_name: '',
      unit_id: 1,
      list_price: 0,
      list_price_currency: '',
      is_deleted: false,
    }
    const usersData = ref(null)
    store
      .dispatch('app/fetchUsers', {
        list_type: 'all',
        list_for: 'proposal',
      })
      .then(response => {
        const { users } = response.data.data
        const arr = []
        users.forEach(val => {
          arr.push({ label: `${val.name} (${val.email})`, value: val.id })
        })

        usersData.value = arr
      })
      .catch(() => {
      })
    store
      .dispatch('app/fetchBankAccounts', {
        list_type: 'all',
      })
      .then(response => {
        const { data } = response.data
        const arr = []
        data.forEach(val => {
          arr.push({ label: `${val.name}`, value: val.id })
        })

        bankAccountsData.value = arr
      })
      .catch(() => {
      })
    const proposalData = ref({
      id: 0,
      proposal_user: 0,
      representative_user: 0,
      proposal_number: '',
      customer_id: 0,
      customer_name: '',
      customer_manager_name: '',
      customer_manager_surname: '',
      customer_manager_email: '',
      customer_manager_id: 0,
      validity_days: 1,
      bank_account_id: 0,
      lang: currentLocale.value.locale,
      start_date: null,
      customer_is_temporary: false,
      clauses: [],
      products: [

      ],
    })

    const PRODUCT_APP_STORE_MODULE_NAME = 'app-proposal'

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, proposalsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
    })

    const customerSelected = option => {
      if (option.item.id !== 0) {
        store.dispatch('app/fetchSingleCustomer', { id: option.item.id })
          .then(response => {
            const { data } = response.data
            proposalData.value.customer_name = data.name
            proposalData.value.customer_id = data.id
            proposalData.value.proposal_user = data.user_id
            const arr = []
            response.data.data.managers.forEach(val => {
              arr.push({
                label: `${val.name} (${val.email})`, value: val.id, name: val.name, surname: val.surname, email: val.email,
              })
              proposalData.value.customer_manager_id = val.id
              customerManagerOptions.value = arr
            })
          })
      }
    }
    const onSubmit = () => {
      store.dispatch('app-product/addProduct')
        .then(response => {
          router.push({ name: 'products-edit', params: { id: response.data.data.id } })
        })
    }
    store.dispatch('app/fetchUnitTypes', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ text: val.name, value: val.id }))
        unitOptions.value = arr
      })
    store.dispatch('app/fetchTaxTypes', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ text: val.name, value: val.id, tax_rate: val.tax_rate }))
        taxOptions.value = arr
      })
    store.dispatch('app/fetchCurrencyTypes', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({
          text: val.name, value: val.id, is_main: val.is_main, currency: val.currency,
        }))
        currencyOptions.value = arr
      })
    if (router.currentRoute.params.id !== undefined) {
      store.dispatch('app-proposal/fetchProposal', { id: router.currentRoute.params.id })
        .then(response => {
          proposalData.value = response.data.data
          currentLocale.value = locales.find(l => l.locale === proposalData.value.lang)
        })
        .catch(error => {
          if (error.response.status === 404) {
            alert('Error')
          }
        })
    }

    const fetchStandardClauses = lang => {
      store.dispatch('app-proposal/fetchStandardClauses', { lang })
        .then(response => {
          const { data } = response.data
          proposalData.value.clauses = data
        })
        .catch(() => {
          this.showErrorMessage()
        })
    }

    const resetTemporaryCustomerData = () => {
      temporaryCustomerData.value = JSON.parse(JSON.stringify(blankTemporaryCompanyData))
    }
    const resetproductData = () => {

    }
    resetTemporaryCustomerData()
    if (router.currentRoute.params.id !== undefined) {
      store.dispatch('app-proposal/fetchProposal', { id: router.currentRoute.params.id })
        .then(response => {
          proposalData.value = response.data.data
          const optionD = { item: { id: proposalData.value.customer_id } }
          customerSelected(optionD)
        })
        .catch(error => {
          if (error.response.status === 404) {
            alert('Error')
          }
        })
    } else {
      fetchStandardClauses(proposalData.value.lang)
    }

    setTimeout(() => { pageIsLoaded.value = true }, 2000)
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetproductData)
    return {
      proposalData,
      onSubmit,
      unitOptions,
      currencyOptions,
      resetTemporaryCustomerData,
      blankTemporaryCompanyData,
      refFormObserver,
      getValidationState,
      pageIsLoaded,
      blankClauseData,
      taxOptions,
      resetForm,
      bankAccountsData,
      fetchStandardClauses,
      blankProductData,
      temporaryCustomerData,
      customerManagerOptions,
      currentLocale,
      customerSelected,
      usersData,
      priceInputConfig: {
        numeralDecimalScale: 4,
        numeral: true,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}
.list-group-item {
  transition: all 1s
}
.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
